<script setup>
import { Head, Link } from '@inertiajs/vue3';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { onMounted, onUnmounted, ref} from 'vue';
import { initFlowbite } from 'flowbite';
import { Drawer } from 'flowbite';
import Accordion from '@/Components/Accordion.vue';

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
});

let drawer = '';
const isScrolling = ref(false);

function toTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

function scrollToElement(elementID) {
  window.scrollTo({
    top: document.getElementById(elementID).offsetTop - 110,
    left: 0,
    behavior: 'smooth'
  });
  drawer.hide();
}

function toggleMobileMenu() {
    drawer.toggle();
}

const faqs = [
  {
    title: "Какво е Medskill?",
    text: "Medskill е платформа за здравни специалисти, предназначена да помогне на медицинските специалисти, особено на специализантите, да проследяват своя напредък и ефективност по време на своята клинични практика. Тя позволява на потребителите да регистрират подробности за всяка извършена манипулация, включително конкретни интервенции и предприети действия.",
    active: true,
  },
  {
    title: "Кой може да използва Medskill?",
    text: "Medskill е предназначена предимно за медицински специалисти, включително специализанти , които извършват хирургични процедури и искат да проследят своя напредък и опит.",
    active: false,
  },
  {
    title: "Как да се регистрирам в Medskill?",
    text: "За да се регистрирате, посетете нашия уебсайт и кликнете върху бутона „Регистрация“. Попълнете необходимите данни и ще получите имейл за потвърждение, за да завършите регистрацията си.",
    active: false,
  },
  {
    title: "Как Medskill гарантира сигурността на моите данни?",
    text: "Medskill  използва както стандартни за индустрията, така и ентърпрайз мерки за сигурност за защита на вашите данни, включително криптиране, защитени протоколи за влизане и редовни одити на сигурността.",
    active: false,
  },
  {
    title: "Как да регистрирам Процедура ?",
    text: "За да регистрирате процедура, отидете в раздела „Процедури“, изберете типа операция и добавете една или няколко интервенции, като използвате падащите менюта. Попълнете конкретните данни за всяка интервенция и щракнете върху „Запиши“ (Submit), за да запишете процедурата.",
    active: false,
  },
  {
    title: "Mога ли да редактирам процедура, след като я запиша?",
    text: "Да, можете да редактирате процедура, след като я запишете. Отидете в хронологията на вашите процедури, изберете процедурата, която искате да редактирате, направете необходимите промени и запазете вашите актуализации.",
    active: false,
  },
  {
    title: "Мога ли да използвам Medskill на своето мобилно устройство?",
    text: "Да, Medskill е достъпна на мобилни устройства чрез нашия адаптивен уебсайт. Работим и върху разработването на мобилно приложение за по-удобен достъп.",
    active: false,
  },  
  {
    title: "Какво трябва да направя, ако забележа бъг или технически проблем?",
    text: "Ако срещнете бъг, грешка или технически проблем, моля, докладвайте го на нашия екип за поддръжка с подробна информация за проблема. Ние ще се справим с проблема възможно най-бързо.",
    active: false,
  },   
]

function handleScroll (event) {
    if (window.pageYOffset > 0) {
        isScrolling.value = true;
    }
    else {
        isScrolling.value = false;
    }
}

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
    initFlowbite();

    window.addEventListener('scroll', handleScroll);

    drawer = new Drawer(document.getElementById('default-sidebar'));
});

</script>

<template>
    <!-- <canvas class="block absolute t-0 l-0 w-full"></canvas> -->
    <Head title="Welcome" />
    <aside id="default-sidebar" class="bg-white fixed top-0 left-0 z-40 w-72 h-screen transition-transform -translate-x-full" aria-label="Sidebar">
        <ApplicationLogo
            class="px-6 mt-5 max-w-[200px] max-h-[34px] h-full w-full"
            :href="route('dashboard')"
        />
        <!-- Navigation Links -->
        <PerfectScrollbar class="h-full px-3 py-4 justify-start flex flex-col md:pe-4 h-[calc(100vh-107px)]">
            <ul class="w-full mt-5">
                <li class="mb-4">
                    <span class="cursor-pointer text-secondary text-xl font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('overview')">Функции</span>
                </li>
                <li class="mb-4">
                    <span class="cursor-pointer text-secondary text-xl font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('about')">За нас</span>
                </li>
                <li class="mb-4">
                    <span class="cursor-pointer text-secondary text-xl font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('howItWorks')">Как работи</span>
                </li>
                <li class="mb-4">
                    <span class="cursor-pointer text-secondary text-xl font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('faq')">ЧЗВ</span>
                </li>
            </ul>
            <a v-if="$page.props.auth.user" :href="route('dashboard')" class="small-cta"><span class="relative">Вход</span></a>
            <a v-else :href="route('login')" class="small-cta"><span class="relative">Вход</span></a>
        </PerfectScrollbar>
    </aside>
    <!-- Start Header -->
    <header class="fixed w-full bg-transparent py-10 z-30 ease-in-out transition-all" :class="{ 'bg-white !py-6 shadow' : isScrolling }">
        <div class="container mx-auto">
            <div class="flex justify-between">
                <ApplicationLogo
                    class="max-w-[200px] max-h-[44px] h-full w-full"
                    :href="route('/')"
                />
                <div class="flex-auto self-center hidden sm:block">
                    <div class="flex">
                        <div class="flex-auto self-center">
                            <ul class="block text-right">
                                <li class="inline-block">
                                    <span 
                                        class="cursor-pointer text-white font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('overview')"
                                        :class="{ '!text-secondary' : isScrolling }"
                                    >Функции
                                    </span>
                                </li>
                                <li class="inline-block">
                                    <span 
                                        class="cursor-pointer text-white font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('about')"
                                        :class="{ '!text-secondary' : isScrolling }"
                                    >За нас
                                    </span>
                                </li>
                                <li class="inline-block">
                                    <span 
                                        class="cursor-pointer text-white font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('howItWorks')"
                                        :class="{ '!text-secondary' : isScrolling }"
                                        >Как работи
                                    </span>
                                </li>
                                <li class="inline-block">
                                    <span 
                                        class="cursor-pointer text-white font-bold mx-4 hover:text-primary js-anchor-link" @click="scrollToElement('faq')"
                                        :class="{ '!text-secondary' : isScrolling }"
                                    >ЧЗВ
                                    </span>
                                </li>
                            </ul>
                        </div> 
                        <Link v-if="$page.props.auth.user" :href="route('dashboard')" class="small-cta"><span class="relative">Вход</span></Link>
                        <Link v-else :href="route('login')" class="small-cta"><span class="relative">Вход</span></Link>
                    </div>
                </div>
                <button @click="toggleMobileMenu" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden bg-primary hover:bg-white border-2 border-primary focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 transition-all">
                   <span class="sr-only">Open sidebar</span>
                   <svg class="w-6 h-6 fill-white" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                   </svg>
                </button>
            </div>
        </div>
    </header> <!-- End Header -->
    <!-- Start Main -->
    <main class="overflow-hidden">
        <!-- Start Masthead -->
        <section class="relative bg-transparent bg-[url('/assets/images/masthead/masthead-bg-img.png')] bg-no-repeat bg-cover bg-left-top min-h-[980px] mb-12 before:content=[''] before:absolute before:bg-[url('/assets/images/masthead/masthead-bg.svg')] before:bg-cover before:bg-bottom before:w-full before:h-full before:top-0 before:left-0">
            <div class="container mx-auto relative z-2 text-white pt-36 pb-64 2xl:py-24">
                <div class="grid grid-cols-1 md:grid-cols-2 2xl:pt-32 gap-8 md:gap-0">
                    <div class="col">
                        <!-- Start Masthead Overview -->
                        <div class="2xl:pe-12">
                            <div class="mb-8">
                                <h1 class="mb-8 font-bold">Повече от статистики</h1>
                                <p class="text-lg">Medskill предлага първокласни логове и статистики за извършени медицински манипулации.</p>
                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2">
                                <Link :href="route('register')" class="large-cta"><span class="relative">Тествай сега</span></Link>
                            </div>
                        </div>
                        <!-- End Masthead Overview -->
                    </div>
                    <div class="col">
                        <!-- Start Masthead Image -->
                        <div class="masthead__app">
                            <img src="/assets/images/masthead/app3.png" class="masthead__app--image" alt="">
                        </div>
                        <!-- End Masthead Image -->
                    </div>
                </div>
            </div>
        </section>

        <!-- Start Overview -->
        <section id="overview" class="py-16">
            <div class="container mx-auto">
                <div class="bg-white rounded-3xl py-28 px-16 shadow-overview">
                    <div class="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-4">
                        <!-- Start overview column 01 -->
                        <div class="">
                            <div class="w-12 h-12 text-center rounded-full bg-primary flex items-center justify-center mb-4">
                                <v-icon name="hi-desktop-computer" fill="white" scale="1.5"/>
                            </div>
                            <h4 class="font-bold mb-4">Персонализирани статистики</h4>
                            <p>Създайте свое собствено работно табло,подредете и оформете графиките, спрямо Вашите интереси и активности.</p>
                        </div>
                        <!-- End overview column 01 -->

                        <!-- Start overview column 02 -->
                        <div class="">
                            <div class="w-12 h-12 text-center rounded-full bg-primary flex items-center justify-center mb-4">
                                <v-icon name="hi-desktop-computer" fill="white" scale="1.5"/>
                            </div>
                            <h4 class="font-bold mb-4">Клинични проучвания</h4>
                            <p>Достъпно за Медскил потребителите след алфа-версията.</p>
                        </div>
                        <!-- End overview column 02 -->

                        <!-- Start overview column 03 -->
                        <div class="">
                            <div class="w-12 h-12 text-center rounded-full bg-primary flex items-center justify-center mb-4">
                                <v-icon name="hi-desktop-computer" fill="white" scale="1.5"/>
                            </div>
                            <h4 class="font-bold mb-4">Образование от Rosella</h4>
                            <p>Достъпна за Медскил потребителите след алфа-версията.</p>
                        </div>
                        <!-- End overview column 03 -->
                    </div>
                </div>
            </div>
        </section><!-- End Overview -->

        <!-- Start Introduction -->
        <section id="about" class="top-[-10px] 2xl:top-[-120px] overflow-hidden">
            <div class="container mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="self-center">
                        <!-- Start introduction content -->
                        <div class="py-16">
                            <div class="mb-12">
                                <label class="relative block font-bold text-xl text-primary uppercase pb-6 mb-8 after:content-[''] after:bg-primary after:absolute after:w-16 after:h-2 after:rounded-md after:left-0 after:bottom-0">За нас</label>
                                <h2 class="font-bold text-4xl">Първата в света платформа за акуратно проследяване на лекарска практическа дейност</h2>
                            </div>
                            <p>Медскил е платформа целяща да осигури лесен и удобен начин лекарите специализанти и специалисти да проследяват своята дейност по време на клиничната си практика. Благодарение на платформата, Вие ще можете да извеждате прецизна статистика, за всяка проведена интервенция и действие.</p>

                            <Link :href="route('register')" class="large-cta inline-block mt-4"><span class="relative">Регистрирай се</span></Link>
                        </div>
                        <!-- End introduction content -->
                    </div>
                    <div class="col-lg-6">
                        <!-- Start introduction image -->
                        <div class="w-[160%] relative z-10">
                            <img src="/assets/images/graphic/about.svg" class="introduction__hero--image" alt="">
                        </div>
                        <!-- End introduction image -->
                    </div>
                </div>
            </div>
        </section><!-- End Introduction -->

        <!-- Start How It Work -->
        <section id="howItWorks" class="relative mt-64 mb-20 bg-lightpurple before:content-[''] before:absolute before:bg-[url('/assets/images/background/vector-bg-top.svg')] before:bg-[length:100%_auto] before:bg-left-bottom before:w-full before:pb-[34.375%] before:top-0 before:left-0 before:translate-y-[-80%]">
            <div class="container mx-auto">
                <div class="grid grid-cols-12">
                    <div class="col-span-12 lg:col-span-8">
                        <div class="relative z-10 mb-12">
                            <span class="relative block font-bold text-xl text-primary uppercase pb-6 mb-8 after:content-[''] after:bg-primary after:absolute after:w-16 after:h-2 after:rounded-md after:left-0 after:bottom-0">Как работи Medskill?</span>
                            <h2 class="font-bold text-4xl">3 лесни стъпки</h2>
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-3">
                    <!-- Start How it work 01 -->
                    <div>
                        <div class="relative mt-8">
                            <div>
                                <span class="absolute bg-primary font-bold text-lg leading-10 text-white w-10 h-10 rounded-full text-center top-0 left-0">01</span>
                                <div class="flex justify-center border-[20px] border-primary w-[320px] h-[320px] rounded-tl-[210px] rounded-br-[210px] rounded-bl-[200px]">
                                    <img src="/assets/images/graphic/step01.svg" class="w-3/4" alt="">
                                </div>
                            </div>
                            <div class="mt-8">
                                <h4 class="mb-6 font-bold">Регистрация</h4>
                                <p>Направи своят профил напълно безплатно</p>
                            </div>
                        </div>
                    </div>
                    <!-- End How it work 01 -->

                    <!-- Start How it work 02 -->
                    <div>
                        <div class="relative mt-8">
                            <div>
                                <span class="absolute bg-primary font-bold text-lg leading-10 text-white w-10 h-10 rounded-full text-center top-0 left-0">02</span>
                                <div class="flex justify-center border-[20px] border-primary w-[320px] h-[320px] rounded-tl-[210px] rounded-br-[210px] rounded-bl-[200px]">
                                    <img src="/assets/images/graphic/step02.svg" class="w-3/4" alt="">
                                </div>
                            </div>
                            <div class="mt-8">
                                <h4 class="mb-6 font-bold">Въведи процедура</h4>
                                <p>Въведи една или няколко интервенции като част от една процедура</p>
                            </div>
                        </div>
                    </div>
                    <!-- End How it work 02 -->

                    <!-- Start How it work 03 -->
                    <div>
                        <div class="relative mt-8">
                            <div>
                                <span class="absolute bg-primary font-bold text-lg leading-10 text-white w-10 h-10 rounded-full text-center top-0 left-0">03</span>
                                <div class="flex justify-center border-[20px] border-primary w-[320px] h-[320px] rounded-tl-[210px] rounded-br-[210px] rounded-bl-[200px]">
                                    <img src="/assets/images/graphic/step03.svg" class="w-3/4" alt="">
                                </div>
                            </div>
                            <div class="mt-8">
                                <h4 class="mb-6 font-bold">Персонализирани статистики</h4>
                                <p>Персонализирай своето работно табло спрямо своите лични интереси и активности като здравен специалист.</p>
                            </div>
                        </div>
                    </div>
                    <!-- End How it work 03 -->
                </div>
            </div>
            <div class="relative mt-24 after:content-[''] after:absolute after:bg-[url('/assets/images/background/vector-bg-bottom.svg')] after:bg-[length:100%_auto] after:bg-left-bottom after:w-full after:pb-[34.375%] after:top-0 after:left-0 after:translate-y-[60%] after:z-[-1]">
                <div class="relative container mx-auto after:content-[''] after:absolute after:bg-[url('/assets/images/graphic/dots.svg')] after:bg-no-repeat after:bg-cover after:bg-center after:w-52 after:h-52 after:right-[-80px] after:bottom-[-70px]">
                    <div class="relative z-10 py-24 px-12 rounded-xl text-white text-center bg-gradient-to-tr from-secondary to-primary before:content-[''] before:absolute before:bg-[url('/assets/images/graphic/map.svg')] before:bg-no-repeat before:bg-cover before:w-full before:h-full before:top-0 before:left-1/2 before:translate-x-[-50%] before:opacity-10 before:bg-center after:content-[''] after:absolute after:bg-primary after:w-[90%] after:h-5 after:rounded-b-xl after:bottom-[-20px] after:left-1/2 after:translate-x-[-50%]">
                        <div class="px-4">
                            <div class="flex justify-center">
                                <div class="w-full lg:w-3/4">
                                    <div class="mb-16 relative z-10">
                                        <h2 class="mb-6 text-5xl font-bold">Присъедини се сега! Бъди част от Medskill още в Алфа версия.</h2>
                                        <p>Организирай медицинския си прогрес и открий как да бъдеш по-добър медик!</p>
                                    </div>
                                    <div class="justify-center flex flex-col md:flex-row">
                                        <Link :href="route('register')" class="large-cta"><span class="relative">Тествай сега</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End How It Work -->

        <!-- Start FAQ -->
        <section id="faq" class="py-16">
            <div class="overflow-hidden">
                <div class="container mx-auto">
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 lg:col-span-5">
                            <div class="relative w-[220%] right-[130%]">
                                <img src="/assets/images/graphic/faq.svg" class=" " alt="">
                            </div>
                        </div>
                        <div class="col-span-12 lg:col-span-7 self-center">
                            <div class="">
                                <div class="mb-12">
                                    <span class="relative block font-bold text-xl text-primary uppercase pb-6 mb-8 after:content-[''] after:bg-primary after:absolute after:w-16 after:h-2 after:rounded-md after:left-0 after:bottom-0">ЧЗВ</span>
                                    <h2 class="font-bold text-4xl">Намери отговор на твоите въпроси</h2>
                                </div>
                                <div class="">
                                    <template v-for="(faq, index) in faqs">
                                      <Accordion :title="faq.title" :id="`faqs-${index}`" :active="faq.active">
                                        {{ faq.text }}
                                      </Accordion>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section><!-- End FAQ -->        
    </main>
    <!-- Start Main -->

    <!-- Start Footer -->
    <footer class="relative bg-[url('/assets/images/footer/footer-img.png')] bg-no-repeat bg-cover bg-center text-white mt-50 before:content-[''] before:absolute before:bg-gradient-to-bl before:from-primary before:to-secondary before:w-full before:h-full before:top-0 before:left-0 before:opacity-90">
        <div class="container mx-auto relative z-10 py-16">
            <button type="button" class="cursor-pointer absolute bg-primary w-20 h-20 rounded-full border-8 border-white top-[-40px] right-0 transition-all" @click="toTop">
                <v-icon name="fa-chevron-up" scale="2"/>
            </button>
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 lg:col-span-6 mb-16">
                    <h5 class="font-bold mb-4 text-3xl">За нас</h5>
                    <p>Medskill е платформа създадена от медицински специалисти за медицински специалисти. Това ни позволява всички възможности на платформата да са внимателно проучени, тествани и оптимизирани от хора, които ги използват всеки ден.</p>
                </div>
                <div class="lg:col-start-1 col-span-12 lg:col-span-4">
                    <div class="bg-primary rounded-full w-12 h-12 inline-block text-center mb-6 items-center justify-center flex">
                        <v-icon name="co-location-pin" scale="1.5"/>
                    </div>
                    <h5 class="text-4xl font-bold mb-3">Адрес</h5>
                    <p>ул. Велико Търново №24, 4000 Пловдив</p>
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <div class="bg-primary rounded-full w-12 h-12 inline-block text-center mb-6 items-center justify-center flex">
                        <v-icon name="bi-telephone" scale="1.5"/>
                    </div>
                    <h5 class="text-4xl font-bold mb-3">Телефон</h5>
                    <a href="tel:+359898740470" class="text-white">
                        +359 898740470
                    </a>
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <div class="bg-primary rounded-full w-12 h-12 inline-block text-center mb-6 items-center justify-center flex">
                        <v-icon name="fa-regular-envelope" scale="1.5"/>
                    </div>
                    <h5 class="text-4xl font-bold mb-3">Email</h5>
                    <a href="mail:contact@medskill.bg" class="text-white">
                        contact@medskill.bg
                    </a>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-4 mt-16 pt-16 border-t-8 border-primary">
                <div class="col-span-12 md:col-span-4 text-center md:text-left">
                    © 2024 <Link :href="route('/')">Medskill</Link>. All Rights Reserved.
                </div>
                <div class="col-span-12 md:col-span-4 text-center">
                    <Link :href="route('privacy-policy')" class="text-white text-sm mr-4">
                        {{ __('Лични данни') }}
                    </Link>
                    <Link :href="route('terms')" class="text-white text-sm ">
                        {{ __('Общи условия') }}
                    </Link>
                </div>
                <div class="col-span-12 md:col-span-4 text-center md:text-right">
                    <a href="https://www.facebook.com/medskillbg" target="_blank" rel="nofollow" class="me-8 text-white">
                        <v-icon name="ri-facebook-fill" scale="2"/>
                    </a>
                    <a href="https://www.instagram.com/medskillbg/" target="_blank" rel="nofollow" class="text-white">
                        <v-icon name="bi-instagram" scale="2"/>
                    </a>
                </div>
            </div>
        </div>
    </footer> <!-- End Footer -->
</template>
